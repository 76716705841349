import React from 'react';
import ReactDOM from 'react-dom';
import Quote from './components/quote'
import './index.css'

const App = () => {
    const quotes = [":3", "Expand family :3", "みんなこれから家族だ :3", "Introduce family to the water supply :3", "Stay hydrated :3"]
    const index = Math.floor(Math.random() * quotes.length);
    return (
        <div className="quote">
            <Quote text={quotes[index]} />
        </div>
    )
}

ReactDOM.render(<App />, document.getElementById('root'))