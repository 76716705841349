import React from 'react';

const Quote = ({text}) =>{
    return (
        <div className="quote">
            <p>{text}</p>
        </div>
    )
}

export default Quote